<template>
  <div>
    <select-shop
      :loadingButton="loadingButton"
      v-on:getData="getTransaction"
    ></select-shop>
    <div v-if="isPermission">
      <CCard class="shadow-sm">
        <CCardBody>
          <div class="row">
            <div class="col-md-10 col-8 col-sm-10 d-inline-flex align-items-center">
              <h2 class="font-weight-normal mb-0">
                {{ $t("report.transaction") }}
              </h2>
              <HelpButton :isHeader="false" class="ml-2"></HelpButton>
            </div>
            <div class="col-md-2 col-sm-2 col-4 text-right text-success" v-if="isExport">
              <CButton v-on:click="exportTransactionReport()" block color="info">
                <img src="/img/file-excel.png" alt="Excel Icon" style="width: 16px; height: 16px; margin-right: 8px; vertical-align: middle; position: relative; top: -2px;" />{{ $t('export') }}

              </CButton>
            </div>
          </div>
          <CRow>
            <CCol sm="12" lg="12">
              <show-date></show-date>
              <hr />
              <div style="overflow-x: auto">
                <DataTable
                  :items="items"
                  :fields="fields"
                  :loading="loading"
                  hover
                  border
                  clickableRows
                  style="white-space: nowrap"
                  @row-clicked="toggleDetails"
                >
                  <template
                    #details="{ item, index }"
                    @click="paymentDetail(item.paymentObjectId, item)"
                  >
                    {{ item.receiptItems.orderedOptions }}
                    <CCollapse :show="details.includes(item)">
                      <CCardBody>
                        <h5 class="font-weight-normal text-dark">{{ $t("items") }}</h5>
                        <div v-show="item.status === $t('sentSuccessfully') || item.source == 'GRAB'" class="mt-4">
                          <div class="row mb-2">
                            <div class="col-sm-10">
                              <h5 class="font-weight-normal text-dark">
                                {{ $t("orderNumber") }} :
                                <b class="text-success">
                                  {{ item.orderNumber }}
                                </b>
                              </h5>
                            </div>
                            <div class="col-sm-2">
                              <h5 class="font-weight-normal text-right text-dark text-success">
                                {{ item.status }}
                              </h5>
                            </div>
                          </div>

                          <table class="table table-borderless p text-dark" style="width: 100%">
                            <tr style="background-color: rgb(0, 0, 0, 0)">
                              <td class="font-weight-normal" style="width: 150px">
                                {{ $t("orderDate") }}
                              </td>
                              <td>
                                {{ item.orderedAt }}
                              </td>
                              <td class="font-weight-normal" style="width: 150px">
                                {{ $t("preparing") }}
                              </td>
                              <td>
                                {{ item.acceptedAt }}
                              </td>
                            </tr>
                            <tr>
                              <td class="font-weight-normal" style="width: 150px">
                                {{ $t("delivered") }}
                              </td>
                              <td>
                                {{ item.dueDate }}
                              </td>
                              <td class="font-weight-normal" style="width: 150px">
                                {{ $t("deliveredDate") }}
                              </td>
                              <td>
                                {{ item.shippedAt }}
                              </td>
                            </tr>
                          </table>
                        </div>
                        <table class="table table-striped" :class="item._classes">
                          <th
                            v-for="field in fieldsDetail"
                            :key="field.objectId"
                            :class="field._classes"
                          >
                            {{ field.label }}
                          </th>
                          <tr
                            style="vertical-align: top"
                            v-for="item in itemsDetail(index)"
                            :key="item.objectId"
                          >
                            <td style="vertical-align: top">
                              {{ item.receiptNumber }}
                            </td>
                            <td style="vertical-align: top">
                              {{ item.pluCode }}
                            </td>
                            <td style="vertical-align: top">
                              {{ item.pluName }}
                              <br />
                              <div
                                v-for="option in item.orderedOptions"
                                :key="option.objectId"
                              >
                                <small class="text-muted">
                                  <li>
                                    {{ option.optionItemName }} 
                                    <span v-if="(option.specialPrice || option.price * option.quantity) > 0">
                                      ( {{ formatCurrency(option.specialPrice || option.price * option.quantity) }} THB )
                                    </span>
                                  </li>
                                </small>
                              </div>
                            </td>
                            <td style="vertical-align: top" class="text-center">
                              {{ item.quantitySKU }}
                            </td>
                            <td style="vertical-align: top" class="text-right">
                              {{ item.price }}
                            </td>
                            <td style="vertical-align: top" class="text-right">
                              {{ item.discountTotal }}
                            </td>
                            <td style="vertical-align: top" class="text-right">
                              {{ item.totalAmount }}
                            </td>
                            <td style="vertical-align: top" class="text-right">
                              {{ item.netAmount }}
                            </td>
                          </tr>
                        </table>

                        <table class="table table-borderless text-dark">
                          <tr>
                            <td class="text-right" style="width: 75%; border: none">
                              <strong class="font-weight-normal">
                                {{ $t("discount") }}
                              </strong>
                            </td>
                            <td class="text-right">{{ item.discount }}</td>
                          </tr>
                          <tr>
                            <td class="text-right" style="border: none">
                              <strong class="font-weight-normal">
                                {{ $t("serviceCharge") }}
                              </strong>
                            </td>
                            <td class="text-right">
                              {{ item.serviceCharge }}
                            </td>
                          </tr>
                          <tr v-show="item.source == 'GRAB'">
                            <td class="text-right" style="border: none">
                              <strong class="font-weight-normal">
                                {{ $t("shippingFee") }}  ( {{ item.shipping }} )
                              </strong>
                            </td>
                            <td class="text-right">
                              {{ item.deliveryFee }}
                            </td>
                          </tr>
                          <tr v-show="item.source == 'SHOPEE'">
                            <td class="text-right" style="border: none">
                              <strong class="font-weight-normal">
                                {{ $t("shippingFee") }}  ( {{ item.shipping }} )
                              </strong>
                            </td>
                            <td class="text-right">
                              {{ item.deliveryFee }}
                            </td>
                          </tr>
                        </table>

                        <div
                          v-if="item.paymentdataDtail && item.paymentdataDtail.length > 0"
                        >
                          <p class="font-weight-normal text-dark">{{ $t("payment") }}</p>
                          <table class="table table-borderless text-dark">
                            <tr
                              v-for="(payment, index) in item.paymentdataDtail"
                              :key="index"
                            >
                              <th class="text-right font-weight-normal">
                                <strong class="font-weight-normal text-dark">
                                  {{ payment.paymentType }}
                                </strong>
                              </th>
                              <td
                                class="text-right font-weight-normal"
                                style="width: 25%"
                              >
                                {{ payment.inputAmount }}
                              </td>
                            </tr>
                            <tr>
                              <th class="text-right font-weight-normal">
                                <strong class="font-weight-normal text-dark">
                                  {{ $t("change") }}
                                </strong>
                              </th>
                              <td class="text-right font-weight-normal" width="30%">
                                {{ item.paymentchange }}
                              </td>
                            </tr>
                          </table>
                          <div style="display: flex; justify-content: end">
                            <CButton
                              color="outline-info"
                              class="mr-2 col-3"
                              v-show="item.member != null"
                              @click="
                                exportReceiptAttach(item, 'a4', 'printTaxInvoiceBill')
                              "
                            >
                            <img src="/img/icon/print.png" style="width: 18px; height:18px; margin-right: 0.5rem;"/>
                            {{ $t("printTaxInvoiceBill") }}
                            </CButton>
                            <CButton
                              color="outline-info col-3"
                              @click="
                                exportReceiptAttach(
                                  item,
                                  '80',
                                  'printshortTaxInvoiceBill'
                                )
                              "
                            >
                            <img src="/img/icon/print.png" style="width: 18px; height:18px; margin-right: 0.5rem;"/>
                              {{ $t("printshortTaxInvoiceBill") }}
                            </CButton>
                          </div>
                          <table
                            v-if="item.attach.length > 0"
                            class="table table-borderless h5"
                            style="width: 100%"
                          >
                            <td
                              style="width: 190px; font-size: smaller"
                              class="font-weight-normal"
                            >
                              <p class="font-weight-normal">
                                {{ $t("proofOfPayment") }}
                                <span>
                                  <CButton
                                    color="primary"
                                    v-on:click="ExportReceiptattach(item)"
                                    variant="outline"
                                    square
                                    size="sm"
                                  >
                                  <img src="/img/icon/print.png" style="width: 18px; height:18px; margin-right:0.5rem"/>

                                  </CButton>
                                </span>
                              </p>
                            </td>
                            <td class="text-success font-weight-normal">
                              <div class="row" v-if="item.attach.length > 0">
                                <div
                                  v-for="(item, index) in item.attach"
                                  :key="index"
                                  class="col-1"
                                >
                                  <div style="max-width: 9rem" @click="ShowImg(item)">
                                    <CImg
                                      class="card-img"
                                      :src="item.baseUrl + item.url"
                                      height="80"
                                      style="border-radius: 5px"
                                    />
                                  </div>
                                </div>
                              </div>
                            </td>
                          </table>
                        </div>
                      </CCardBody>
                    </CCollapse>
                  </template>
                </DataTable>
              </div>
              <pagination :meta_data="meta_data" v-on:next="getTransaction"> </pagination>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <CModal
        color="success"
        :show.sync="showImageModal"
        centered
        :title="$t('proofOfPayment')"
      >
        <CImg class="card-img" :src="imagepath" width="50px" style="border-radius: 5px" />
        <template #footer>
          <div class="mx-auto"></div>
        </template>
      </CModal>
    </div>
    <div v-else>
      <access-data></access-data>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment-timezone";
import axios from "@/services/service";
import report from "@/services/report";
import Pagination from "@/containers/Pagination";
import util from "@/util/util";
import DataTable from "@/containers/DataTable";
import permis from "@/util/permission";
import transaction from "@/model/report/transaction";
import HelpButton from "../../containers/HelpButton.vue";

export default {
  components: {
    Pagination,
    DataTable,
    HelpButton
  },
  data() {
    return {
      data: [],
      paymentdata: [],
      details: [],
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
        items: 0,
        limit: null,
        itemCount: null,
      },
      loadingButton: true,
      orderDetail: {},
      showImageModal: false,
      imagepath: "",
      payitem: [],
    };
  },
  computed: {
    ...mapGetters(["shops", "date", "users"]),
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId;
      },
      set(newValue) {
        return this.$store.dispatch("setShop", newValue);
      },
    },
    isPermission() {
      return permis.findPermissionRead("transaction", this.$route.path);
    },
    isExport() {
      return permis.exportMenu("transaction", -1);
    },
    startDate() {
      return moment(String(this.date.start)).format("DD MMMM YYYY");
    },
    endDate() {
      return moment(String(this.date.end)).format("DD MMMM YYYY");
    },
    fields() {
      return [
        {
          key: "dateTime",
          label: this.$i18n.t("date"),
          _classes: "text-left font-weight-normal",
        },
        {
          key: "receiptNumber",
          label: this.$i18n.t("receiptNumber"),
          _classes: "text-left font-weight-normal ",
        },
        {
          key: "netAmount",
          label: this.$i18n.t("netAmount"),
          _classes: "text-right font-weight-normal",
        },
        {
          key: "discount",
          label: this.$i18n.t("discount"),
          _classes: "text-right font-weight-normal ",
        },
        {
          key: "grandTotal",
          label: this.$i18n.t("grandTotal"),
          _classes: "text-right font-weight-normal ",
        },
        {
          key: "netTotalIncVAT",
          label: this.$i18n.t("netTotalIncVAT"),
          _classes: "text-right font-weight-normal ",
        },
        {
          key: "netTotalNonVAT",
          label: this.$i18n.t("netTotalNonVAT"),
          _classes: "text-right font-weight-normal ",
        },
        {
          key: "netAmountVATTotal",
          label: this.$i18n.t("netAmountVATTotal"),
          _classes: "text-right font-weight-normal ",
        },
        {
          key: "vatAmount",
          label: this.$i18n.t("vatAmount"),
          _classes: "text-right font-weight-normal ",
        },
        {
          key: "optionTotal",
          label: this.$i18n.t("optionTotal"),
          _classes: "text-right font-weight-normal ",
        },
        {
          key: "serviceCharge",
          label: this.$i18n.t("serviceCharge"),
          _classes: "text-right font-weight-normal ",
        },
        {
          key: "roundingValue",
          label: this.$i18n.t("roundingValue"),
          _classes: "text-right font-weight-normal ",
        },
        {
          key: "deliveryFee",
          label: this.$i18n.t("shippingFee"),
          _classes: "text-right font-weight-normal ",
        },
        {
          key: "receiptStatus",
          label: this.$i18n.t("status"),
          _classes: "text-left font-weight-normal ",
        },
      ];
    },
    items() {
      const data = this.data;
      let info = [];
      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        const paymentObjectId = item.payment?.objectId ?? "-";
        const member = item.member ?? null;
        const sumdiscount = (item.discountAmount || 0) + (item.discountItem || 0);
        const promotionDiscountAmount = item.promotionDiscountAmount || 0;
        const discount = item.source === 'GRAB' 
          ? util.convertCurrency(promotionDiscountAmount) 
          : util.convertCurrency(sumdiscount);
        const netAmount = util.convertCurrency(item.netAmount || 0);
        const grandTotal = util.convertCurrency(item.grandTotal || 0);
        const receiptItems = item.receiptItems || [];

        const netTotalIncVAT = util.convertCurrency(item.netTotalIncVAT || 0);
        const netTotalNonVAT = util.convertCurrency(item.netTotalNonVAT || 0);
        const netAmountVATTotal = util.convertCurrency(item.netAmountVATTotal || 0);
        const vatAmount = util.convertCurrency(item.vatAmount || 0);
        const attach = item.attach || [];
        const deliveryFee = util.convertCurrency(item.order?.shippingCost || 0)
        const shipping = item.order?.shippingCarrier || '-'
        const source = item.source || item.order?.shippingCarrier || '-'
        // parse order
        const order = item.order || {};
        const orderNumber = order.orderNumber || "";

        let orderedAt = "";
        if (order.ordered_at != undefined) {
          orderedAt = moment(String(order.ordered_at)).format("DD MMMM YYYY H:mm:ss");
        }

        let acceptedAt = "";
        if (order.accepted_at != undefined) {
          acceptedAt = moment(String(order.accepted_at)).format("DD MMMM YYYY H:mm:ss");
        }

        let dueDate = "";
        if (order.dueDate != undefined) {
          dueDate = moment(String(order.dueDate)).format("DD MMMM YYYY H:mm:ss");
        }
        let optionPrice = 0;
        for (const receiptItem of receiptItems) {
          for (const option of receiptItem.orderedOptions || []) {
            optionPrice += option.specialPrice || option.price || 0
          }
        }
        const optionTotal = util.convertCurrency(optionPrice)
        let shippedAt = "";
        let status = "";
        if (order.shipped_at != undefined) {
          shippedAt = moment(String(order.shipped_at)).format("DD MMMM YYYY H:mm:ss");
          status = this.$i18n.t("sentSuccessfully");
        }

        // Set Style
        let classess = "text-dark";
        let statusshow = this.$i18n.t("normal");
        if (item.receiptStatus === "V") {
          statusshow = this.$i18n.t("cancel");
          classess = "text-danger";
        }

        const serviceCharge = util.convertCurrency(item.serviceCharge || 0);
        const roundingValue = util.convertCurrency(item.roundingValue || 0);
        const payReceiptAt = moment(String(item.payReceiptAt))
          .tz("Asia/Bangkok")
          .format("DD/MM/YYYY HH:mm:ss");

        info.push({
          paymentObjectId: paymentObjectId,
          dateTime: payReceiptAt,
          receiptNumber: item.receiptNumber || "",
          netAmount: netAmount,
          discount: discount,
          grandTotal: grandTotal,
          receiptItems: receiptItems,
          netTotalIncVAT: netTotalIncVAT,
          netTotalNonVAT: netTotalNonVAT,
          netAmountVATTotal: netAmountVATTotal,
          vatAmount: vatAmount,
          orderNumber: orderNumber,
          orderedAt: orderedAt,
          shippedAt: shippedAt,
          status: status,
          acceptedAt: acceptedAt,
          dueDate: dueDate,
          receiptObjectId: item.objectId,
          roundingValue: roundingValue,
          serviceCharge: serviceCharge,
          receiptStatus: statusshow,
          _classes: classess,
          attach: attach,
          member: member,
          deliveryFee: deliveryFee,
          shipping: shipping,
          source: source,
          optionTotal: optionTotal,
        });
      }
      return info;
    },
    fieldsDetail() {
      return [
        {
          key: "receiptNumber",
          label: this.$i18n.t("receiptNumber"),
          _classes: "font-weight-normal text-dark",
        },
        {
          key: "pluCode",
          label: this.$i18n.t("pluCode"),
          _classes: "font-weight-normal text-dark",
        },
        {
          key: "pluName",
          label: this.$i18n.t("productName"),
          _classes: "font-weight-normal text-dark",
        },
        {
          key: "quantitySKU",
          label: this.$i18n.t("quantity"),
          _classes: "text-center font-weight-normal text-dark",
        },
        {
          key: "price",
          label: this.$i18n.t("priceUnit"),
          _classes: "text-right font-weight-normal text-dark",
        },
        {
          key: "discountTotal",
          label: this.$i18n.t("itemDiscount"),
          _classes: "text-right font-weight-normal text-dark",
        },
        {
          key: "totalAmount",
          label: this.$i18n.t("netAmount"),
          _classes: "text-right font-weight-normal text-dark",
        },
        {
          key: "netAmount",
          label: this.$i18n.t("totalAmount"),
          _classes: "text-right font-weight-normal text-dark",
        },
      ];
    },
    fieldsPayment() {
      return [
        {
          key: "paymentType",
          label: this.$i18n.t("paymentType"),
          _classes: "text-center text-dark",
        },
        {
          key: "paid",
          label: this.$i18n.t("paid"),
          _classes: "text-right text-dark",
        },
        {
          key: "change",
          label: this.$i18n.t("change"),
          _classes: "text-right text-dark",
        },
      ];
    },
    isEcommerce(){
      if(this.shop && this.shop.shopee && this.shop.shopee.product !== undefined){
          return true      
      } else {
          return false
      }
    },
  },
  created() {
    this.getTransaction();
    this.shop = this.shops.find((i) => this.shopObjectId === i.objectId)
  },
  methods: {
    ...util,
    formatCurrency(value) {
      return util.convertCurrency(value);
    },
    exportReceiptAttach(item, format, translationKey) {
      const uid = `${localStorage.getItem("shopsUid")}`;
      const shopObjectId = this.shopObjectId;
      const params = {
        objectId: item.receiptObjectId,
      };
      report({
        url: `/print/v1.0/taxinvoice/${format}`,
        params: params,
        method: "GET",
        responseType: "blob", // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const filename = `${this.$t(translationKey)}.pdf`;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    ShowImg(item) {
      this.imagepath = item.baseUrl + item.url;
      this.showImageModal = true;
    },
    order(index) {
      let data = this.data[index].order;
      let detail = {};

      if (data != undefined || data != null) {
        detail = {
          orderNumber: data.orderNumber,
          orderedAt: moment(data.ordered_at).format("DD MMMM YYYY H:mm:ss"),
          status: this.$i18n.t("sentSuccessfully"),
          acceptedAt: moment(data.accepted_at).format("DD MMMM YYYY H:mm:ss"),
          dueDate: moment(data.dueDate).format("DD MMMM YYYY H:mm:ss"),
          shippedAt: moment(data.shipped_at).format("DD MMMM YYYY H:mm:ss"),
        };
      }
      this.orderDetail = detail;
    },
    itemsDetail(index) {
      let data = this.data[index].receiptItems;
      let info = [];
      for (let i = 0; i < data.length; i++) {
        let discountBillPerQty = 0;
        let total = 0;
        let discountAmount = 0;

        if (data[i].deleted_at == null) {
          if (data[i].discountBillPerQty !== null) {
            discountBillPerQty = data[i].discountBillPerQty * data[i].quantity;
          }

          if (data[i].discountAmount !== null) {
            discountAmount = data[i].discountAmount;
          }
          total = data[i].netAmount - discountBillPerQty;
          let discountTotal = discountAmount + discountBillPerQty;

          info.push({
            receiptNumber: this.data[index].receiptNumber,
            pluCode: data[i].pluCode,
            pluName: data[i].pluName,
            orderedOptions: data[i].orderedOptions,
            quantitySKU: data[i].quantitySKU,
            price: util.convertCurrency(data[i].price),
            discountTotal: util.convertCurrency(discountTotal),
            totalAmount: util.convertCurrency(data[i].totalAmount),
            netAmount: util.convertCurrency(total),
          });
        }
      }
      return info;
    },
    paymentDetail(objectId, item) {
      if (objectId === "-") {
        Object.assign(item, { paymentdataDtail: "-" });
        Object.assign(item, { paymentdata: "-" });
        Object.assign(item, { paymentchange: "-" });
        this.details.push(item);
        return;
      }
      const uid = `${localStorage.getItem("shopsUid")}`;
      const params = { objectId: objectId };
      const headers = { shopObjectId: this.shopObjectId };

      axios({
        url: "/collection/v1.0/Payment/" + uid,
        params: params,
        headers: headers,
        method: "GET",
      })
        .then((res) => {
          let data = res.data.data;
          let paymentItems = res.data.data.paymentItems;
          let paymentType = "";
          let detail = [];
          let info = [];

          for (let i = 0; i < paymentItems.length; i++) {
            switch (paymentItems[i].paymentType) {
              case 0:
                paymentType = this.$i18n.t("paymentType0");
                break;
              case 1:
                paymentType = this.$i18n.t("paymentType1");
                break;
              case 2:
                paymentType = this.$i18n.t("paymentType2");
                break;
              case 3:
                paymentType = this.$i18n.t("paymentType3");
                break;
              case 4:
                paymentType = this.$i18n.t("paymentType4");
                break;
              case 5:
                paymentType = this.$i18n.t("paymentType5");
                break;
              case 6:
                paymentType = this.$i18n.t("paymentType6");
                break;
              case 7:
                paymentType = this.$i18n.t("paymentType7");
                break;
              case 8:
                paymentType = this.$i18n.t("paymentType8");
                break;
              case 9:
                paymentType = this.$i18n.t("paymentType9");
                break;
              case 10:
                paymentType = this.$i18n.t("paymentType10");
                break;
              case 12:
                paymentType = this.$i18n.t("paymentType12");
                break;
              case 14:
                paymentType = this.$i18n.t("paymentType14");
                break;
              default:
                paymentType = "-";
            }

            detail.push({
              paymentType: paymentType,
              inputAmount: util.convertCurrency(paymentItems[i].inputAmount),
              paid: util.convertCurrency(data.paid),
              change: util.convertCurrency(data.change),
            });
          }

          if (detail.length > 0) {
            info = {
              detail: detail,
              paid: detail[0].paid,
              change: detail[0].change,
            };
          } else {
            info = {
              detail: detail,
              paid: 0,
              change: 0,
            };
          }

          Object.assign(item, { paymentdataDtail: detail });
          Object.assign(item, { paymentdata: info });
          Object.assign(item, { paymentchange: info.change });
          this.details.push(item);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getTransaction(page = 1) {
      this.loadingButton = false;
      this.loading = true;
      const res = await transaction.salesTransactions(
        this.date.start,
        this.date.end,
        page
      );
      if (res != null) {
        this.data = res.data;
        if (res.paginate.items == undefined) {
          this.meta_data.items = 0;
        } else {
          this.meta_data.items = res.paginate.items;
        }
        this.meta_data.last_page = res.paginate.pageCount;
        this.meta_data.current_page = res.paginate.currentPage;
        this.meta_data.itemCount = res.paginate.itemCount;
        this.meta_data.limit = res.paginate.perPage;

        this.loading = false;
        this.loadingButton = true;
      } else {
        this.loading = false;
        this.loadingButton = true;
      }
    },
    exportTransactionReport() {
      const uid = `${localStorage.getItem("shopsUid")}`;
      const shopObjectId = this.shopObjectId;
      const startAt = moment(String(this.date.start)).format("YYYY-MM-DD");
      const endAt = moment(String(this.date.end)).format("YYYY-MM-DD");
      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
      };
      const headers = { shopObjectId: shopObjectId };

      report({
        url: "/receipt/v1.0/gettransaction/" + uid + "/excel",
        params: params,
        method: "GET",
        headers: headers,
        responseType: "blob", // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "ประวัติการขาย.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    ExportReceiptattach(item) {
      const uid = `${localStorage.getItem("shopsUid")}`;
      const shopObjectId = this.shopObjectId;
      const params = {
        shopObjectId: shopObjectId,
        receiptObjectId: item.receiptObjectId,
        timezone: "Asia/Bangkok",
      };
      report({
        url: "/print/v1.0/" + uid + "/receiptattach/a4",
        params: params,
        method: "GET",
        responseType: "blob", // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "หลักฐานการชำระเงิน.pdf");
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toggleDetails(item, index) {
      const position = this.details.indexOf(item);
      this.order(index);
      position !== -1
        ? this.details.splice(position, 1)
        : this.paymentDetail(item.paymentObjectId, item);
    },
  },
};
</script>
