import moment from 'moment-timezone'
import axios from '@/services/report'

let salesTransactions = async (startDate, endDate, page) => {
    const uid = `${localStorage.getItem('shopsUid')}`
    const shopObjectId = `${localStorage.getItem('shopObjectId')}`

    const startAt = moment(String(startDate)).format('YYYY-MM-DD')
    const endAt = moment(String(endDate)).format('YYYY-MM-DD')

    const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
        page: page,
        limit: 50,
    }
    try {
        const res = await axios({
            url: '/receipt/v1.0/gettransaction/' + uid,
            params: params,
            method: 'GET',
        })
        const responseData = {
            'data': res.data.data,
            'paginate': res.data.paginate
        }
        return responseData
    } catch (error) {
        // Handle errors
        console.log(error)
        return null
    }
}

export default {
    salesTransactions
}
  

